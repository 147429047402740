import { onBeforeMount } from 'vue';
import { isUserAgentSupported } from '../../__generated__/support';

export function useBrowserSupportCheck(noSupportFn: () => void): void {
  onBeforeMount(() => {
    if (!isBrowserSupported()) {
      noSupportFn();
    }
  });
}

export function isBrowserSupported(): boolean {
  if (import.meta.env.MODE === 'test') {
    return true;
  }

  if (!isUserAgentSupported(navigator.userAgent)) {
    return false;
  }

  // Note: we should likely expose feature requirements for round
  // as we should have a base support level for surveys without video questions
  // at moment we assert browser support based on media recorder only
  // we can't do this when question is served as we would terminate mid round and
  // that is a more broken experience
  const isMediaRecorderSupported =
    'MediaRecorder' in window &&
    (MediaRecorder.isTypeSupported('video/webm;codec=h264') ||
      MediaRecorder.isTypeSupported('video/mp4;codec=h264'));

  return isMediaRecorderSupported;
}
