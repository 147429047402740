import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';
import 'normalize.css';
import './styles/main.postcss';
import './styles/button.postcss';
import './styles/select.postcss';
import App from './components/pages/App.vue';
import routes from './routes';
import marketeerRoutes from '../marketeer-legacy/routes';
import {
  setLocale,
  transformLanguageToTranslationKey,
  isRtlLocale,
  DEFAULT_LANGUAGE,
  DEFAULT_COUNTRY,
} from '../intl';
import { capture, captureMetric, setGlobalContext, setupErrorTracking } from './setup';
import { initialise as initialiseConfig } from '../config';
import { terminate } from '../interview/apis/terminate';
import { isBrowserSupported } from '../interview/hooks/useBrowserSupportCheck';

(async function main() {
  try {
    await initialiseConfig();
    const app = createApp(App);
    setupErrorTracking(app);
    setGlobalContext();
    captureMetric.increment('browser_support', 1, {
      tags: { agent: navigator.userAgent, supported: isBrowserSupported() },
    });

    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get('language')?.toUpperCase() ?? DEFAULT_LANGUAGE;
    const country = searchParams.get('country_of_residence_v2')?.toUpperCase() ?? DEFAULT_COUNTRY;
    await setLocale(transformLanguageToTranslationKey(language, country));
    if (isRtlLocale()) {
      window.document.body.dir = 'rtl';
    }

    const router = createRouter({
      history: createWebHistory(import.meta.env.BASE_URL),
      routes: [
        {
          path: '/app',
          component: () => import('./components/pages/UnresponsiveContainer.vue'),
          children: routes,
        },
        { path: '/preview', component: () => import('./components/pages/PreviewPage.vue') },
        { path: '/marketeer', children: marketeerRoutes },
        {
          path: '/:pathMatch(.*)*',
          component: () => import('./components/pages/PageNotFound.vue'),
        },
      ],
    });

    const pinia = createPinia();

    app.use(router);
    app.use(pinia);
    app.mount('#app');
  } catch (e) {
    capture(e as Error);
    term();
  }
})();

async function term(): Promise<void> {
  try {
    const roundGuid = window.location.pathname.match(/\/?app\/(?<guid>[a-zA-Z0-9-]+)/)?.groups
      ?.guid;
    if (roundGuid) {
      await terminate({ roundGuid, reason: 'term' });
    }
  } catch (e) {
    capture(e as Error);
  }
}
