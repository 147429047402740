export type ApiTerminate = {
  'redirect-url'?: string;
};

export type TerminateReason = 'browser_not_supported' | 'opted_out' | 'term';

export const terminate = async ({
  roundGuid,
  reason,
}: {
  roundGuid: string;
  reason: TerminateReason;
}): Promise<ApiTerminate> => {
  return await (
    await fetch('/api/v2/term', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        round_guid: roundGuid,
        reason,
      }),
    })
  ).json();
};
