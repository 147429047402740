export function replacePlaceholders<PlaceholderName extends string>(
  copy: string,
  placeholders: {
    [p in PlaceholderName]?: string;
  },
): string {
  return copy.replace(
    /\[%s:(.+?)]/g,
    (_fullMatch, placeholderName: PlaceholderName) => placeholders[placeholderName] ?? '',
  );
}
